import { getQuiz } from 'API/getQuiz'
import { getUserData } from '../../../../API/user'
import { QuizRenderer } from 'components/quiz/Renderer'
import { Link, PageProps } from 'gatsby'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import Webcam from 'react-webcam'
import { QuizStateActions } from 'redux/slices/QuizState'
import { CustomModal } from 'components/common/useModal'
import { start_quiz } from 'API/quiz'
import { PromiseType } from 'utility-types'

import { CourseDisplayStateActions } from 'redux/slices/CourseDisplayState'
import { handleApi } from 'API/handleApiCall'
import axios from 'axios'
import { getDefaultOptions } from 'API/auth'
import { getUrl } from 'API/getUrl'
import { addFileToCDNForProctorExam } from 'pages/course/[id]/classwork/add/addFileToCDN'

const Image = '/quizFinish.svg'

const Quiz: React.FC<PageProps> = ({ params: { quiz_id, id } }) => {
	const [asset, setAsset] = React.useState<PromiseType<ReturnType<typeof getQuiz>>>()
	const [docStatus, setDocStatus] = React.useState({ status: 'NA', comment: '' })
	const [userData, setUserData] = React.useState<PromiseType<ReturnType<typeof getUserData>>>()
	const [data, setData] = React.useState({
		student_photo: '',
		document1: '',
		document2: '',
	})
	const FACING_MODE_USER = 'user'
	const FACING_MODE_ENVIRONMENT = 'environment'
	const [update, setUpdate] = React.useState(0)
	const [showdoc, setdoc] = React.useState(true)
	const webcamRef = React.useRef(null)
	const [url, setUrl] = React.useState(null)

	React.useEffect(() => {
		const run = async () => {
			// runAuth()
			if (!showdoc) {
				const userData = await getUserData()
				if (userData) {
					setUserData(userData)
				}
			}
		}
		run()
		// async function get_data() {
		// getUserData(await ApiLinks.user_data(localStorage addFileToCDNForPublic.getItem('uid')))
		// }
		// get_data()
	}, [showdoc])

	React.useEffect(() => {
		const run = async () => {
			const get_status = await handleApi<any>(
				axios.get(getUrl(`quiz/proctor/get_document_status?quiz_id=${quiz_id}&course_id=${id}`), getDefaultOptions())
			)
			if (get_status) {
				setDocStatus({ status: get_status.status, comment: get_status.comment })
				console.log(get_status)
			} else {
				setDocStatus({ status: 'NA', comment: '' })
			}
		}
		run()
	}, [quiz_id, id])
	const videoConstraints = {
		width: 540,
		facingMode: 'environment',
	}

	const handlesubmit = async () => {
		if (userData && quiz_id && id) {
			console.log(data)
			if (data.document1 === '' || data.document2 === '' || data.student_photo === '' || data.student_photo === null) {
				alert('Please upload all the documents')
			} else {
				const doc_submission = await handleApi<any>(
					axios.post(
						getUrl('quiz/proctor/document_submission'),
						{
							quiz_id: quiz_id,
							document1: data.document1,
							document2: data.document2,
							student_photo: data.student_photo,
							course: id,
						},
						getDefaultOptions()
					)
				)
				if (doc_submission) {
					console.log(doc_submission)
					alert('Documents Submitted Successfully')
					setDocStatus({ status: doc_submission.status, comment: doc_submission.comment })
					setdoc(true)
				}
			}
		}
	}
	const capturePhoto = React.useCallback(
		async (e) => {
			e.preventDefault()
			if (webcamRef.current) {
				const imageSrc = await webcamRef.current.getScreenshot()
				setUrl(imageSrc)
				const res = await fetch(imageSrc)
				const buf = await res.arrayBuffer()
				const file = new File([buf], questionData.uid + '_' + localStorage.getItem('uid') + '_' + new Date().getTime(), {
					type: 'image/jpeg',
				})
				console.log(file)
				const student_photo = await addFileToCDNForProctorExam({
					file: file,
					name: file.name,
				})
				console.log(student_photo)
				if (student_photo) {
					setData({ ...data, student_photo: student_photo.uid })
				}
			}
		},
		[webcamRef.current]
	)
	const onUserMedia = (e: any) => {
		console.log(e)
	}
	const dispatch = useAppDispatch()
	const viewAs = useAppSelector((state) => state.CourseDisplayReducer.viewAs)
	const quizData = useAppSelector((state) => state.QuizReducer.attemptData)
	const questionData = useAppSelector((state) => state.QuizReducer.questionData)
	const questions = useAppSelector((state) => state.QuizReducer.questions)

	React.useEffect(() => {
		const run = async () => {
			const data = await getQuiz({ quiz_id, id, viewAs: 'Student' })
			if (viewAs !== 'Student') {
				dispatch(CourseDisplayStateActions.setViewAs('Student'))
			}
			if (data) {
				setAsset(data)
				console.log(data)
				let questionsInternal = []
				if (data.order) {
					try {
						for (let index = 0; index < data.order.length; index++) {
							// const element = questionsInternal[index]
							// console.log({ element }, data.order)
							questionsInternal.push(data?.questions?.filter((elem) => elem?.id === data?.order?.[index])?.[0])
						}
					} catch (err) {
						console.error(err)
						questionsInternal = []
					}
				}
				if (questionsInternal.length === 0) {
					dispatch(
						QuizStateActions.setState({
							questionData: {
								...data,
							},
							questions: questionsInternal,
						})
					)
				} else {
					dispatch(
						QuizStateActions.setState({
							questionData: {
								...data,
							},
							questions: data.questions,
						})
					)
				}
			}
		}

		run()
	}, [id, quiz_id, viewAs, update])

	let ref = React.useRef<HTMLButtonElement | null>(null)

	React.useEffect(() => {
		const interval = setInterval(() => {
			// console.log(interval, 'interval')
			if (quizData.attempting && asset) {
				// setUpdate((update) => update + 1)
				updateTimer()
			}
		}, 1000)
		return () => {
			clearInterval(interval)
		}
	}, [quizData.attempting, asset])

	const updateTimer = async () => {
		if (asset && asset.started_at) {
			if (!asset.finished && new Date(asset.started_at).getTime() < Date.now()) {
				const timeSpent = new Date().getTime() - new Date(asset.started_at).getTime()

				const totalTime = asset.durationInSeconds * 1000
				let timeLeft = totalTime - timeSpent
				if (Date.now() + timeLeft > new Date(asset.available_till).getTime()) {
					timeLeft = new Date(asset.available_till).getTime() - Date.now()
				}
				// console.log({ timeSpent, totalTime, timeLeft }, 'interval')
				dispatch(
					QuizStateActions.setQuizData({
						...quizData,
						attempting: true,
						timeSpent: timeSpent / 1000,
						timeLeft: (timeLeft > 0 ? timeLeft : 0) / 1000,
						totalTime: totalTime / 1000,
					})
				)

				if (timeLeft < 0) {
					dispatch(QuizStateActions.toggleSave())
					// navigate('/')
					setUpdate((update) => update + 1)
				}
			}
		}
	}

	React.useEffect(() => {
		if (questions.length > 0 && asset?.finished !== true) {
			dispatch(QuizStateActions.setQuizData({ ...quizData, attempting: true }))
		}
	}, [questions, asset])
	const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER)
	const handleClick = React.useCallback(() => {
		event.preventDefault()
		setFacingMode((prevState) => (prevState === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER))
	}, [])
	return (
		<>
			{id && (
				<>
					<CustomModal
						button_action={questions.length > 0 ? 'Finish Quiz' : 'Click on Start'}
						header={asset?.proctor ? 'Exam' : 'Quiz'}
						disabled_back={true}
						onClick={() => {
							console.log(ref)
							if (ref.current) {
								ref.current.click()
							}
						}}
					>
						{asset && (
							<>
								{new Date(asset.available_from) > new Date() && asset.proctor === true && <>Exam Not Started.</>}
								{new Date(asset.available_from) > new Date() && asset.proctor === false && <>Quiz Not Started.</>}
								{asset.finished && asset.proctor === true && (
									<>
										<div className='email-container'>
											<div className='row px-md-2 justify-content-center row-emailContent'>
												<div className='col-md-6 col-emailContent'>
													<h3>{!asset.finished ? 'Exam Missed' : 'The Exam has ended.'}</h3>
													<p className='mb-2'>The Exam has ended.</p>
													<Link to='/' className='btn btn-primary no-hover no-shadow'>
														<i className='bx bx-chevron-left'></i> Go Back
													</Link>
												</div>
												<div className='col-md-6 order-first order-md-last emailImg'>
													<div className='text-center'>
														<img src={Image} alt='' />
													</div>
												</div>
											</div>
										</div>
									</>
								)}
								{asset.finished && asset.proctor === false && (
									<>
										<div className='email-container'>
											<div className='row px-md-2 justify-content-center row-emailContent'>
												<div className='col-md-6 col-emailContent'>
													<h3>{!asset.finished ? 'Quiz Missed.' : 'The quiz has ended.'}</h3>
													<p className='mb-2'>The quiz has ended.</p>
													<Link to='/' className='btn btn-primary no-hover no-shadow'>
														<i className='bx bx-chevron-left'></i> Go Back
													</Link>
												</div>
												<div className='col-md-6 order-first order-md-last emailImg'>
													<div className='text-center'>
														<img src={Image} alt='' />
													</div>
												</div>
											</div>
										</div>
									</>
								)}

								{new Date(asset.available_from) < new Date() &&
									new Date(asset.available_till) > new Date() &&
									!asset.finished &&
									asset.proctor === true && (
										// asset.can_start_exam === true && (
										<>
											{asset.questions.length === 0 && docStatus.status === 'NA' && (
												<div className='btn btn-primary' onClick={() => setdoc(!showdoc)}>
													{showdoc ? 'Submit Documents' : 'Back'}
												</div>
											)}

											{asset.proctor &&
												asset.questions.length === 0 &&
												(docStatus.status === 'Pending' || docStatus.status === 'Rejected') && (
													<>
														<div className='col-lg-6 col-md-6 col-sm-12'>
															<label htmlFor='formFile' className='form-label'>
																Approval Status
															</label>
															<input
																className='form-control'
																type='text'
																id='formFile'
																disabled
																value={docStatus.status}
															/>
														</div>
														<div className='col-lg-6 col-md-6 col-sm-12'>
															<label htmlFor='formFile' className='form-label'>
																Comments
															</label>
															<input
																className='form-control mb-2'
																type='text'
																id='formFile'
																disabled
																value={docStatus.comment}
															/>
														</div>
														{docStatus.status === 'Rejected' && (
															<div className='col-6'>
																<button
																	className='btn btn-primary'
																	onClick={async (e) => {
																		alert('Are you sure you want to re-submit the documents?')

																		const resp = await handleApi<any>(
																			axios.post(
																				getUrl('quiz/proctor/delete_doc'),
																				{ quiz_id: asset.uid },
																				getDefaultOptions()
																			)
																		)
																		if (resp) {
																			console.log(resp)
																			window.location.reload()
																		} else {
																			alert('Something went wrong')
																		}
																		// capturePhoto(e)
																	}}
																>
																	Re-Submit Documents
																</button>
															</div>
														)}
													</>
												)}
											{asset.proctor &&
												!asset.can_start_exam &&
												asset.questions.length === 0 &&
												docStatus.status === 'Approved' && (
													<>
														<div className='col-lg-6 col-md-6 col-sm-12'>
															<label htmlFor='formFile' className='form-label'>
																Approval Status
															</label>
															<input
																className='form-control'
																type='text'
																id='formFile'
																disabled
																value={docStatus.status}
															/>
														</div>
														<div className='col-lg-6 col-md-6 col-sm-12'>
															<label htmlFor='formFile' className='form-label'>
																Please Wait for Proctor to Start the exam
															</label>
														</div>
													</>
												)}
											{asset.questions.length === 0 && docStatus.status === 'Approved' && asset.can_start_exam && (
												<>
													<div
														onClick={async () => {
															document.documentElement.requestFullscreen()
															await start_quiz({ quiz_id })
															setUpdate((update) => update + 1)
															const action = 'EXAM STARTED'
															const action_window_navigated = await handleApi<any>(
																axios.post(
																	getUrl(`quiz/proctor/action_during_exam`),
																	{
																		action_done: action,
																		quiz_id: questionData.uid,
																	},
																	getDefaultOptions()
																)
															)
														}}
														className='btn btn-primary'
													>
														Start Exam
													</div>
												</>
											)}
										</>
									)}
								{new Date(asset.available_from) < new Date() &&
									new Date(asset.available_till) > new Date() &&
									!asset.finished &&
									!asset.proctor && (
										<>
											{/* @todo bad flag fixme */}
											{asset.questions.length === 0 && (
												<>
													<div
														onClick={async () => {
															// document.documentElement.requestFullscreen()
															await start_quiz({ quiz_id })
															setUpdate((update) => update + 1)
														}}
														className='btn btn-primary'
													>
														Start Quiz
													</div>
												</>
											)}
										</>
									)}
								{new Date(asset.available_till) < new Date() && asset.proctor === true && (
									<>
										<div className='email-container'>
											<div className='row px-md-2 justify-content-center row-emailContent'>
												<div className='col-md-6 col-emailContent'>
													<h3>{!asset.finished ? 'Exam Missed.' : 'The Exam has ended.'}</h3>
													<p className='mb-2'>The exam has ended.</p>
													<Link to='/' className='btn btn-primary no-hover no-shadow'>
														<i className='bx bx-chevron-left'></i> Go Back
													</Link>
												</div>
												<div className='col-md-6 order-first order-md-last emailImg'>
													<div className='text-center'>
														<img src={Image} alt='' />
													</div>
												</div>
											</div>
										</div>
									</>
								)}
								{new Date(asset.available_till) < new Date() && asset.proctor === false && (
									<>
										<div className='email-container'>
											<div className='row px-md-2 justify-content-center row-emailContent'>
												<div className='col-md-6 col-emailContent'>
													<h3>{!asset.finished ? 'Quiz Missed.' : 'The quiz has ended.'}</h3>
													<p className='mb-2'>The quiz has ended.</p>
													<Link to='/' className='btn btn-primary no-hover no-shadow'>
														<i className='bx bx-chevron-left'></i> Go Back
													</Link>
												</div>
												<div className='col-md-6 order-first order-md-last emailImg'>
													<div className='text-center'>
														<img src={Image} alt='' />
													</div>
												</div>
											</div>
										</div>
									</>
								)}
							</>
						)}
						{asset?.finished !== true && (
							<>
								{docStatus.status === 'NA' && !showdoc && (
									<form className='mt-5'>
										<div className='container'>
											<div className='row'>
												<div className='col-6'>
													<input
														type='text'
														className='form-control'
														disabled
														placeholder='First name'
														value={userData?.first_name}
													/>
												</div>
												<div className='col-6'>
													<input
														type='text'
														className='form-control'
														disabled
														placeholder='Last name'
														value={userData?.last_name}
													/>
												</div>
												<div className='col-6'>
													<label htmlFor='formFile' className='form-label'>
														Upload Document1 here
													</label>
													<input
														className='form-control'
														type='file'
														id='formFile'
														onChange={async (e) => {
															const files = e.target.files
															if (files !== null) {
																const upload1 = await addFileToCDNForProctorExam({
																	file: files[0],
																	name: files[0].name,
																})
																if (upload1) {
																	console.log(upload1.uid)
																	data.document1 = upload1.uid
																}
															}
														}}
													/>
												</div>
												<div className='col-6'>
													<label htmlFor='formFile' className='form-label'>
														Upload Document2 here
													</label>
													<input
														className='form-control'
														type='file'
														id='formFile'
														onChange={async (e) => {
															const files = e.target.files
															if (files !== null) {
																const upload2 = await addFileToCDNForProctorExam({
																	file: files[0],
																	name: files[0].name,
																})
																if (upload2) {
																	console.log(upload2)
																	data.document2 = upload2.uid
																}
															}
														}}
													/>
												</div>
												<div className='container mt-2'>
													<label htmlFor='formFile' className='form-label'>
														Take a photo with aadhar card in your hand Face should be clearly visible.
													</label>
													<div>
														<div className='row'>
															<div className='col-6'>
																<Webcam
																	ref={webcamRef}
																	style={{ width: '100%' }}
																	// audio={true}
																	screenshotFormat='image/jpeg'
																	onUserMedia={onUserMedia}
																	mirrored={true}
																	videoConstraints={{
																		...videoConstraints,
																		facingMode,
																	}}
																/>
															</div>

															<div className='col-6'>
																{data.student_photo !== null && url && (
																	<div>
																		<img src={url} alt='Screenshot' />
																	</div>
																)}
															</div>
															<div className='col-12 mb-2'>
																<button className='btn btn-primary' onClick={handleClick}>
																	Switch camera
																</button>
															</div>
															<div className='col-6'>
																<button
																	className='btn btn-primary mb-2'
																	onClick={(e) => {
																		capturePhoto(e)
																	}}
																>
																	Capture
																</button>
															</div>
															<div className='col-6'>
																<button
																	className='btn btn-primary'
																	onClick={(e) => {
																		e.preventDefault()
																		setUrl(null)
																		setData({ ...data, student_photo: '' })
																	}}
																>
																	Re-Take
																</button>
															</div>
														</div>

														<div className='col-6'>
															<button
																className='btn-lg btn-primary'
																onClick={(e) => {
																	e.preventDefault()
																	handlesubmit()
																}}
															>
																Submit
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</form>
								)}

								{showdoc && (
									<QuizRenderer
										webcam={true}
										getRef={(_ref) => {
											if (_ref) ref = _ref
										}}
										viewAs='Student'
										course={id}
										renderAs={'attempting'}
										proctor={asset?.proctor}
										exam_ended={asset?.finished}
									></QuizRenderer>
								)}
							</>
						)}
					</CustomModal>
				</>
			)}
		</>
	)
}

export default Quiz
